<template>
  <h5 class="text-center">
    Nomor Tagihan: <b>{{ incomeDepositSelected.code }}</b>
  </h5>
  <h6 class="text-center">Silahkan transfer ke:</h6>
  <div class="row">
    <div
      v-for="data in officialBankAccount"
      :key="data.id"
      class="col-md-4"
    >
      <div class="card">
        <div class="card-body text-sm">
          <img :src="data.logo" class="img-fluid" />
          {{ data.bank_name }}<br />
          <b class="text-primary">{{ data.account_number }}</b
          ><br />
          {{ data.account_name }}<br />
        </div>
      </div>
    </div>
  </div>
  <h6 class="mt-4 text-center">
    Total yang harus ditransfer: <br />
    <h2 class="text-primary">
      {{ incomeDepositSelected.display_fund_tobe_transfered }}
    </h2>
    (Penambahan jumlah dana deposit {{ incomeDepositSelected.display_fund_tobe_transfered }})
    <br />
    <div v-if="incomeDepositSelected.status == 'success'" class="badge badge-success">{{ incomeDepositSelected.status }}</div>
    <div v-else-if="incomeDepositSelected.status == 'pending'" class="badge badge-secondary">{{ incomeDepositSelected.status }}</div>
    <div v-else-if="incomeDepositSelected.status == 'expired'" class="badge badge-danger">{{ incomeDepositSelected.status }}</div>
  </h6>
</template>

<script>
export default {
  name: "BillDepositInformation",
  props: {
    incomeDepositSelected: { required: true, type: Object },
    officialBankAccount: { required: true, type: Object },
  },
};
</script>

<style></style>
