<template>
  <div class="row">
    <div class="col-md-3">
      <div class="mt-2">
        <default-info-card
          :icon="{
            component: 'fas fa-money-bill-wave',
            background: 'bg-gradient-success',
          }"
          title="Penghasilan"
          description="Total Saldo Saat Ini"
          :value="balance"
        />
      </div>
    </div>
    <div class="col-md-3">
      <div class="mt-2">
        <default-info-card
          :icon="{
            component: 'fas fa-file-invoice-dollar',
            background: 'bg-gradient-danger',
          }"
          title="NonCOD"
          description="Tariff + Product"
          :value="bill"
        />
      </div>
    </div>
    <div class="col-md-3">
      <div class="mt-2">
        <default-info-card
          :icon="{
            component: 'fas fa-file-download',
            background: 'bg-gradient-info',
          }"
          title="Penarikan"
          description="Success Withdraw"
          :value="withdraw"
        />
      </div>
    </div>
    <div class="col-md-3">
      <div class="mt-2">
        <default-info-card
          :icon="{
            component: 'fas fa-funnel-dollar',
            background: 'bg-gradient-primary',
          }"
          title="Setoran"
          description="Manual Deposit"
          :value="income_deposit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import DefaultInfoCard from "@/examples/Cards/DefaultInfoCard.vue";
export default {
  name: "FinanceSummary",
  components: {
    DefaultInfoCard,
  },
  props: {
    balance: { required: false, type: String, default: "" },
    bill: { required: false, type: String, default: "" },
    withdraw: { required: false, type: String, default: "" },
    income_deposit: { required: false, type: String, default: "" },
  },
};
</script>
