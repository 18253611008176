<template>
  <div v-if="showStatisticCard">
    <div class="row">
      <statistics-card
        v-if="boxBalanceCODSuccess"
        :clickable="clickable"
        :is-loading="isLoading.BalanceCODSuccess"
        title="Berhasil Dikumpulkan"
        :count="totalBalanceCODSuccess"
        :percentage="percentageBalanceCODSuccess"
        menu="All time"
        :dropdown="[
          {
            label: 'All time',
            route: '#',
          },
        ]"
        :is_active="is_active.BalanceCODSuccess"
        @click.prevent="handleViewPackageDetail('balance-cod-success')"
      />
      <statistics-card
        v-if="boxBalanceCODOnProgress"
        :clickable="clickable"
        :is-loading="isLoading.BalanceCODOnProgress"
        title="Belum Mengumpulkan"
        :count="totalBalanceCODOnProgress"
        :percentage="percentageBalanceCODOnProgress"
        menu="All time"
        :dropdown="[
          {
            label: 'All time',
            route: '#',
          },
        ]"
        :is_active="is_active.BalanceCODOnProgress"
        @click.prevent="handleViewPackageDetail('balance-cod-onprogress')"
      />
      <statistics-card
        v-if="boxBalanceCODFail"
        :clickable="clickable"
        title="Gagal Dikumpulkan"
        :is-loading="isLoading.BalanceCODFail"
        :count="totalBalanceCODFail"
        :percentage="percentageBalanceCODFail"
        menu="All time"
        :dropdown="[
          {
            label: 'All time',
            route: '#',
          },
        ]"
        :is_active="is_active.BalanceCODFail"
        @click.prevent="handleViewPackageDetail('balance-cod-fail')"
      />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import StatisticsCard from "@/components/StatisticsCard.vue";
export default {
  name: "OverviewBoxSaldo",
  components: {
    StatisticsCard,
  },
  props: {
    is_admin: { required: false, type: Boolean, default: false },
    clickable: {
      type: Boolean,
      default: true,
    },
    active_selected: { required: false, type: String, default: "" },
  },
  data() {
    return {
      showStatisticCard: true,

      boxBalanceCODSuccess: true,
      boxBalanceCODOnProgress: true,
      boxBalanceCODFail: true,

      dataBalanceCODSuuccess: [],
      dataBalanceCODOnProgress: [],
      dataBalanceCODFail: [],

      totalBalanceCODSuccess: "Rp. 0",
      totalBalanceCODOnProgress: "Rp. 0",
      totalBalanceCODFail: "Rp. 0",

      percentageBalanceCODSuccess: {
        color: "primary",
        value: "0%",
        label: "Jenis Paket COD",
      },
      percentageBalanceCODOnProgress: {
        color: "success",
        value: "0%",
        label: "Jenis Paket COD",
      },
      percentageBalanceCODFail: {
        color: "success",
        value: "0%",
        label: "Jenis Paket COD",
      },

      // if member
      urlread_balance_cod_success: "/api/v1/balance/cod/success",
      urlread_balance_cod_onprogress: "/api/v1/balance/cod/onprogress",
      urlread_balance_cod_fail: "/api/v1/balance/cod/fail",

      // if admin
      urlread_balance_cod_success_admin: "/api/v1/balance/admin/cod/success",
      urlread_balance_cod_onprogress_admin:
        "/api/v1/balance/admin/cod/onprogress",
      urlread_balance_cod_fail_admin: "/api/v1/balance/admin/cod/fail",

      isLoading: {
        BalanceCODSuccess: false,
        BalanceCODOnProgress: false,
        BalanceCODFail: false,
      },
      is_active: {
        BalanceCODSuccess: false,
        BalanceCODOnProgress: false,
        BalanceCODFail: false,
      },
    };
  },
  created() {
    this.getBalanceCODSuccess();
    this.getBalanceCODOnProgress();
    this.getBalanceCODFail();
  },
  methods: {
    checkActiveSelected() {
      if (this.active_selected != "") {
        this.handleViewPackageDetail(this.active_selected);
      }
    },
    async getBalanceCODSuccess() {
      try {
        this.isLoading.BalanceCODSuccess = true;
        let url =
          this.is_admin == true
            ? this.urlread_balance_cod_success_admin
            : this.urlread_balance_cod_success;
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.dataBalanceCODSuccess = response.data.data.data;
        this.totalBalanceCODSuccess =
          response.data.data.display_cash_on_delivery;
        this.percentageBalanceCODSuccess.value =
          response.data.data.display_total_percentage_cod;
        this.isLoading.BalanceCODSuccess = false;

        this.checkActiveSelected();
      } catch (error) {
        console.log(error);
        this.isLoading.BalanceCODSuccess = false;
      }
    },
    async getBalanceCODOnProgress() {
      try {
        this.isLoading.BalanceCODOnProgress = true;
        let url =
          this.is_admin == true
            ? this.urlread_balance_cod_onprogress_admin
            : this.urlread_balance_cod_onprogress;
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.dataBalanceCODOnProgress = response.data.data.data;
        this.totalBalanceCODOnProgress =
          response.data.data.display_cash_on_delivery;
        this.percentageBalanceCODOnProgress.value =
          response.data.data.display_total_percentage_cod;
        this.isLoading.BalanceCODOnProgress = false;

        //console.log(response.data.data.data_cod);

        this.checkActiveSelected();
      } catch (error) {
        console.log(error);
        this.isLoading.BalanceCODOnProgress = false;
      }
    },
    async getBalanceCODFail() {
      try {
        this.isLoading.BalanceCODFail = true;
        let url =
          this.is_admin == true
            ? this.urlread_balance_cod_fail_admin
            : this.urlread_balance_cod_fail;
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        this.dataBalanceCODFail = response.data.data.data;
        this.totalBalanceCODFail = response.data.data.display_cash_on_delivery;
        this.percentageBalanceCODFail.value =
          response.data.data.display_total_percentage_cod;
        this.isLoading.BalanceCODFail = false;

        this.checkActiveSelected();
      } catch (error) {
        console.log(error);
        this.isLoading.BalanceCODFail = false;
      }
    },

    handleViewPackageDetail(type) {
      let values = "";
      let next = false;
      switch (type) {
        case "balance-cod-success":
          this.normalBoxLink();
          values = this.dataBalanceCODSuccess;
          this.is_active.BalanceCODSuccess = true;
          next = true;
          break;
        case "balance-cod-onprogress":
          this.normalBoxLink();
          values = this.dataBalanceCODOnProgress;
          this.is_active.BalanceCODOnProgress = true;
          next = true;
          break;
        case "balance-cod-fail":
          this.normalBoxLink();
          values = this.dataBalanceCODFail;
          this.is_active.BalanceCODFail = true;
          next = true;
          break;
      }

      if (next == true) {
        this.showStatisticCard = false;
        setTimeout(() => {
          this.showStatisticCard = true;
        }, 1);

        this.$emit("viewdetailpackage", values);
      }
    },

    normalBoxLink() {
      this.is_active.BalanceCODSuccess = false;
      this.is_active.BalanceCODOnProgress = false;
      this.is_active.BalanceCODFail = false;
    },
  },
};
</script>

<style></style>
