<template>
  <div class="card">
    <!-- Card header -->
    <div class="card-header">
      <h5 class="mb-0">Transaksi NonCOD</h5>
      <p class="mb-0 text-sm">Daftar transaksi NonCOD yang dibuat.</p>
    </div>
    <div class="card-body" style="border-top: 1px solid #eeeeee">
      <div class="row">
        <div class="col-md-6"></div>
        <div class="col-md-6 text-end">
          <div class="btn-group" role="group">
            <button
              type="button"
              class="btn btn-sm btn-primary"
              @click="refreshTable"
            >
              Refresh Data
            </button>
          </div>
          &nbsp;
        </div>
      </div>
      <div>
        <div
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <el-select
            v-model="pagination.perPage"
            class="select-primary pagination-select"
            placeholder="Per page"
          >
            <el-option
              v-for="item in pagination.perPageOptions"
              :key="item"
              class="select-primary"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>

          <div>
            <el-input
              v-model="searchQuery"
              type="search"
              class="mb-3"
              clearable
              prefix-icon="el-icon-search"
              style="width: 200px"
              placeholder="Search records"
              aria-controls="datatables"
            >
            </el-input>
          </div>
        </div>
        <el-skeleton v-if="loadingTable" :rows="3" animated />
        <div v-else class="table-responsive">
          <el-table
            :style="style"
            :data="queriedData"
            row-key="id"
            header-row-class-name="thead-light"
            @selection-change="selectionChange"
          >
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              v-bind="column"
            >
            </el-table-column>
            <el-table-column align="left" label="Resi">
              <template #default="props">
                {{ props.row.merchant_order_number }}
                <span v-if="is_admin">
                  <br />
                  <small class="text-secondary">
                    {{ props.row.email }}<br />
                    {{ props.row.display_created_at }}
                  </small>
                </span>
              </template>
            </el-table-column>
            <el-table-column v-if="is_admin" align="left" label="Pengirim">
              <template #default="props">
                {{ props.row.from_name }}
                <i
                  v-if="props.row.official_dropship_store_id"
                  class="fas fa-star text-primary"
                  style="font-size: 12px"
                ></i>

                <br /><small class="text-secondary"
                  >{{ props.row.from_phone_number }}<br />{{
                    props.row.from_address_1
                  }}<br /><span style="color: #bbbbbb">{{
                    props.row.origin
                  }}</span></small
                >
              </template>
            </el-table-column>
            <el-table-column v-if="is_admin" align="left" label="Penerima">
              <template #default="props">
                {{ props.row.to_name }}<br /><small class="text-secondary"
                  >{{ props.row.to_phone_number }}<br />{{
                    props.row.to_address_1
                  }}<br /><span style="color: #bbbbbb">{{
                    props.row.destination
                  }}</span></small
                >
              </template>
            </el-table-column>
            <el-table-column min-width="50px" align="left" label="Tagihan">
              <template #default="props">
                {{ props.row.display_bill }}
              </template>
            </el-table-column>
            <el-table-column align="left" label="Status">
              <template #default="props">
                <span
                  v-if="props.row.status == 'Completed'"
                  class="badge badge-sm badge-success"
                  >{{ props.row.status }}</span
                >
                <span
                  v-else-if="props.row.status == 'Successful Delivery'"
                  class="badge badge-sm badge-success"
                  >{{ props.row.status }}</span
                >
                <span v-else class="badge badge-sm badge-primary">{{
                  props.row.status
                }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <br />
        <div
          class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
        >
          <div class="">
            <p class="card-category">
              Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
            </p>
          </div>
          <base-pagination
            v-model="pagination.currentPage"
            class="pagination-no-border"
            :per-page="pagination.perPage"
            :total="total"
          >
          </base-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  ElTable,
  ElTableColumn,
  ElSelect,
  ElOption,
  ElInput,
  ElSkeleton,
} from "element-plus";
import BasePagination from "@/components/BasePagination";

export default {
  name: "BillBalance",
  components: {
    BasePagination,
    [ElSelect.name]: ElSelect,
    [ElOption.name]: ElOption,
    [ElTable.name]: ElTable,
    [ElInput.name]: ElInput,
    [ElTableColumn.name]: ElTableColumn,
    ElSkeleton,
  },
  props: {
    is_admin: { type: Boolean, default: false },
    size: { type: String, default: "md" },
  },
  data() {
    return {
      urlread: "/api/v1/send_packages/noncod",
      urlreadfromadmin: "/api/v1/send_packages/admin/noncod",

      dataTable: [],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: [
        "merchant_order_number",
        "from_name",
        "from_address_1",
        "origin",
        "to_name",
        "email",
        "to_phone_number",
        "to_address_1",
        "destination",
        "status",
        "display_created_at",
        "bill",
        "display_bill",
      ],

      tableColumns: [
        {
          type: "selection",
        },
      ],
      datas: [],
      tableData: [],
      fuseSearch: null,
      searchedData: [],
      isLoading: false,
      loadingTable: true,

      style: "",
    };
  },
  computed: {
    pagedData() {
      return this.tableData.slice(this.from, this.to);
    },
    /***
     * Searches through table data and returns a paginated array.
     * Note that this should not be used for table with a lot of data as it might be slow!
     * Do the search and the pagination on the server and display the data retrieved from server instead.
     * @returns {computed.pagedData}
     */
    queriedData() {
      if (!this.searchQuery) {
        return this.pagedData;
      }
      return this.searchAction.slice(this.from, this.to);
    },
    searchAction() {
      let result = this.tableData.filter((row) => {
        let isIncluded = false;
        for (let key of this.propsToSearch) {
          if (row[key] !== null) {
            let rowValue = row[key].toString().toLowerCase();
            if (
              rowValue.includes &&
              rowValue.includes(this.searchQuery.toLowerCase())
            ) {
              isIncluded = true;
            }
          }
        }
        return isIncluded;
      });

      return result;
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchAction.length > 0
        ? this.searchAction.length
        : this.tableData.length;
    },
  },
  created() {
    this.tableSize();
    this.getTables();
  },
  methods: {
    tableSize() {
      if (this.size == "sm") {
        this.style = "font-size: 12px;";
      }
    },
    async getTables() {
      try {
        this.loadingTable = true;
        let url = this.urlread;
        if (this.is_admin == true) {
          url = this.urlreadfromadmin;
        }
        const response = await axios.get(url, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        });
        //console.log(response);
        this.datas = response.data.data.data;
        this.tableData = this.datas;
        //console.log(response.data.data.display_grandtotal_bill);
        // callback grandtotal_bill
        this.$emit(
          "displaygrandtotalbill",
          response.data.data.display_grandtotal_bill
        );
        this.loadingTable = false;
      } catch (error) {
        console.log(error);
        this.loadingTable = false;
        this.errorRedirect500(error);
      }
    },

    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },

    refreshTable() {
      this.getTables();
    },

    showAddForm() {
      this.$router.push({ path: "/orders/send_packages" });
    },

    errorRedirect500(error) {
      if (error.response.status == 500) {
        setTimeout(() => {
          this.$router.push({ path: "/authentication/error/error500" });
        }, 2000);
      }
    },
  },
};
</script>
